import React, { useEffect, useState } from 'react';
import { supabase } from './SupabaseClient'; // Ensure this imports the configured Supabase client

function Archive() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [selectedDate, setSelectedDate] = useState(null);
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    fetch('/api/archive')
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json(); // Adjust this based on what your API returns
      })
      .then((data) => {
        // You can process data here if needed
        console.log('Fetched data:', data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    generateCalendar(currentYear, currentMonth);
  }, [currentYear, currentMonth]);

  const generateCalendar = (year, month) => {
    const calendarElement = document.getElementById('calendar');
    const currentMonthElement = document.getElementById('currentMonth');

    const firstDayOfMonth = new Date(year, month, 1);
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    calendarElement.innerHTML = '';

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    currentMonthElement.innerText = `${monthNames[month]} ${year}`;

    const firstDayOfWeek = firstDayOfMonth.getDay();

    for (let i = 0; i < firstDayOfWeek; i++) {
      const emptyDayElement = document.createElement('div');
      calendarElement.appendChild(emptyDayElement);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const dayElement = document.createElement('div');
      dayElement.className = 'text-center py-2 border cursor-pointer';
      dayElement.innerText = day;

      const currentDate = new Date();
      if (year === currentDate.getFullYear() && month === currentDate.getMonth() && day === currentDate.getDate()) {
        dayElement.classList.add('bg-[#AD9B9A]', 'text-white');
      }

      dayElement.addEventListener('click', async () => {
        const selectedDate = new Date(year, month, day);
        const startOfSelectedDate = new Date(selectedDate.setHours(0, 0, 0, 0));
        const endOfSelectedDate = new Date(selectedDate.setDate(selectedDate.getDate() + 1));

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = startOfSelectedDate.toLocaleDateString(undefined, options);
        setSelectedDate(formattedDate);

        // Format the selected date as YYYY-MM-DD for the query
        const formattedStartDate = startOfSelectedDate.toISOString();
        const formattedEndDate = endOfSelectedDate.toISOString();

        // Fetch question from Supabase
        const { data, error } = await supabase
          .from('questions')
          .select('value')
          .gte('created_at', formattedStartDate)
          .lt('created_at', formattedEndDate) // Correct the query

        if (error) {
          console.error('Error fetching question:', error);
          setQuestion('No question found for this date.');
        } else if (data.length === 0) {
          setQuestion('No question found for this date.');
        } else {
          setQuestion(data[0].value);
        }

        console.log("question today", data);
      });

      calendarElement.appendChild(dayElement);
    }
  };

  const showModal = () => {
    const modal = document.getElementById('myModal');
    modal.classList.remove('hidden');
  };

  const hideModal = () => {
    const modal = document.getElementById('myModal');
    modal.classList.add('hidden');
  };

  useEffect(() => {
    if (selectedDate) {
      showModal();
    }
  }, [selectedDate]);

  return (
    <div className="bg-[#f6f4f3] flex items-center justify-center h-screen">
      <div className="lg:w-7/12 md:w-9/12 sm:w-10/12 mx-auto p-4">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="flex items-center justify-between px-6 py-3 bg-[#AD9B9A]">
            <button
              id="prevMonth"
              className="text-white"
              onClick={() => {
                setCurrentMonth((prevMonth) => {
                  if (prevMonth === 0) {
                    setCurrentYear((prevYear) => prevYear - 1);
                    return 11;
                  } else {
                    return prevMonth - 1;
                  }
                });
              }}
            >
              Previous
            </button>
            <h2 id="currentMonth" className="text-white"></h2>
            <button
              id="nextMonth"
              className="text-white"
              onClick={() => {
                setCurrentMonth((prevMonth) => {
                  if (prevMonth === 11) {
                    setCurrentYear((prevYear) => prevYear + 1);
                    return 0;
                  } else {
                    return prevMonth + 1;
                  }
                });
              }}
            >
              Next
            </button>
          </div>
          <div className="grid grid-cols-7 gap-2 p-4" id="calendar">
            {/* Calendar Days Go Here */}
          </div>
          <div id="myModal" className="modal hidden fixed inset-0 flex items-center justify-center z-50">
            <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>
            <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
              <div className="modal-content py-4 text-left px-6">
                <div className="flex justify-between items-center pb-3">
                  <button
                    id="closeModal"
                    className="modal-close px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring"
                    onClick={hideModal}
                  >
                    ✕
                  </button>
                </div>
                <div id="modalDate" className="text-xl font-semibold">{selectedDate}</div>
                <div id="modalQuestion" className="text-lg mt-4">{question}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Archive;
