import React from 'react'
import '../App.css'
import Badge from '../components/badge.js'
import AnswerForm from '../components/Answerform'
import Modal from '../components/Modal.js'
import Footer from '../components/Footer.js'
import Askedby from '../components/Askedby.js'
import Archive from './Archive.js'
import { Link } from 'react-router-dom'

function Question({ question }) {
    return (
        <div
            id="questionContainer"
            className="lg:w-full md:mb-2 mb-2 md:mt-5 text-xl md:text-3xl font-medium text-center bg-[#f6f4f3] w-full"
        >
            {!question ? 'Loading...' : question}
        </div>
    )
}

// App component
function Home() {
    const [questionData, setQuestionData] = React.useState({})

    React.useEffect(() => {
        fetch('/question')
            .then((res) => res.json()) // Parse the JSON response
            .then((data) => setQuestionData(data)) // Set the questionPart1 value
            .catch((error) =>
                console.error('Error fetching the question:', error)
            )
        // Handle potential errors
    }, [])

    return (
        <div className="Home bg-[#f6f4f3]">
            <header className="App-header">
                <nav
                    className={`bg-f6f4f3 rounded-lg m-4 dark:bg-white-800 shadow`}
                >
                    <div className="max-w-screen-xl flex items-center md:mx-auto p-4 sm:mx-4">
                        <div className="flex items-center justify-between w-full">
                            <a
                                href="https://puzzlethread.com/"
                                className={`flex items-center ${window.innerWidth <= 640 ? 'space-x-1' : 'space-x-3'} rtl:space-x-reverse ${window.innerWidth <= 640 ? 'w-full justify-start' : ''}`}
                                style={{
                                    maxWidth:
                                        window.innerWidth <= 640
                                            ? '90px'
                                            : 'none',
                                }}
                            >
                                <span
                                    className="self-center text-2xl font-medium whitespace-nowrap dark:text-22031F"
                                    style={{ color: '#22031F' }}
                                >
                                    puzzlethread
                                </span>
                            </a>
                            <div className="flex-grow"></div>
                            <ul className="flex items-center space-x-4">
                                <li className="mx-2">
                                    <Link
                                        to="/archive"
                                        className="hover:underline me-4 md:me-6 text-[#22031F]"
                                    >
                                        Archive
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <div className="body lg:m-8 m-4">
                <div className="grid md:grid-cols-6 grid-cols-6">
                    {/* Modal */}
                    <div className="col-span-6 md:col-span-6 md:col-start-4 md:col-end-6 md:justify-start col-start-4 col-end-7 mb-3 justify-self-end pl-4 md:mt-4">
                        <Modal />
                    </div>

                    {/* Badge */}
                    <div className="md:col-span-4 md:col-start-2 md:col-end-4 md:mt-1 md:text-left col-start-1 col-end-3">
                        <Badge className="text-xs md:text-base" />
                    </div>

                    {/* Question */}
                    <div className="md:col-span-6 md:col-start-2 md:col-end-6 md:mt-1 mt-1 md:mx-2 col-start-1 col-end-7 text-center flex justify-center items-center h-full">
                        <Question
                            question={questionData.question}
                            class="sm:text-xs md:text-base"
                        />
                    </div>

                    {/* Askedby */}
                    <div className="md:col-span-6 md:col-start-4 md:col-end-6 md:text-right md:justify-self-end col-start-4 col-end-7 text-end ml-8 md:mb-3">
                        <Askedby
                            askedBy={questionData.askedBy}
                            className="sm:text-xs md:text-base"
                        />
                    </div>

                    {/* AnswerForm */}
                    <div className="md:col-span-6 md:col-start-2 md:col-end-6 mt-4 text-center col-start-1 col-end-7 mb-2">
                        <AnswerForm />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home
