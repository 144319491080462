import React from 'react';

function About() {
    return (
        <div className="grid grid-cols-6 h-full">
            <div className="col-span-6 md:col-start-3 md:col-end-5 md:col-span-5 flex items-center justify-center md:mt-20 mt-10 mx-8">
                <div className='bg-[#f6f4f3] text-[#AD9B9A] text-sm sm:text-base font-medium py-1 rounded dark:bg-[#f6f4f3] dark:text-[#AD9B9A]'>
                    <p className="text-justify">
                        It started with an argument and a curious question: Can AI be an interesting conversation partner for humans and if so, can you imagine not being able to recognize whether you are talking to another human or AI? Being a good conversation partner is for me about making another person think and come to unexpected conclusions. And very often it is triggered by being able to ask questions that make another person curious. <br/><br/>Puzzlethread is an attempt to see if AI can ask questions that make us pause and think and how they are different from human-generated question. On a simpler level, it is my way to stay curious every day by thinking about the question of the day – whether it is AI- or human-generated. <br/><br/>Questions are generated by GPT-4 and humans - feel free to submit your own.<br/><br/>For feedback and questions: kozlovskaiajulia@gmail.com
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
