'use client'

import { useState, useRef, useEffect } from 'react';

function UserQuestionModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [userQuestion, setUserQuestion] = useState('');
    const modalRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef]);

    const submitHandler = async (e) => {
        e.preventDefault();
        await fetch('/userQuestion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userQuestion: userQuestion,
            }),
        });
        setIsOpen(false); // Close the modal after submitting the form
    };

    return (
        <>
            {/*This is the component to blur the background*/}
            <div
                className={
                    isOpen
                        ? 'fixed z-10 backdrop-blur-sm w-[100vw] h-[100vh] top-0 left-0'
                        : 'hidden'
                }
                onClick={() => setIsOpen(false)}
            ></div>
            <button
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                className="block text-white bg-[#AD9B9A] hover:bg-[#22031F] focus:outline-none focus:ring-4 focus:ring-[#AD9B9A] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-[#AD9B9A] dark:hover:[#22031F] dark:focus:ring-[#22031F]"
                type="button"
                onClick={() => setIsOpen(!isOpen)}
            >
                Ask question
            </button>
            <div
                id="crud-modal"
                aria-hidden="true"
                ref={modalRef}
                className={
                    isOpen
                        ? ''
                        : 'hidden ' +
                          'overflow-y-auto overflow-x-hidden justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full'
                }
            >
                <div className="p-4 w-[70vw] z-50 fixed top-[10vh] left-[15vw]">
                    <div className="bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                Ask your own question
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-toggle="crud-modal"
                                onClick={() => setIsOpen(false)}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form className="p-4 md:p-5" onSubmit={submitHandler}>
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2">
                                    <textarea
                                        id="description"
                                        rows="4"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-[#AD9B9A] focus:border-[#AD9B9A] dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder=""
                                        onChange={(c) => setUserQuestion(c.target.value)}
                                    >
                                        {userQuestion}
                                    </textarea>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="text-white bg-[#AD9B9A] hover:bg-[#22031F] focus:outline-none focus:ring-4 focus:ring-[#AD9B9A] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-[#AD9B9A] dark:hover:[#22031F] dark:focus:ring-[#22031F]"
                            >
                                Ask
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserQuestionModal;
