function Badge() {
    // Get the current date
    const currentDate = new Date()

    // Options for formatting the date
    const options = { month: 'long', day: 'numeric', year: 'numeric' }

    // Format the date as "January 2, 2023"
    const formattedDate = currentDate.toLocaleDateString('en-US', options)

    return (
<div className="badge-container sm:w-[70%]">
    <span
        id="dateBadge"
        className="bg-[#f6f4f3] text-[#AD9B9A] text-sm sm:text-base font-medium py-1 rounded dark:bg-[#f6f4f3] dark:text-[#AD9B9A]"
    >
        {formattedDate}
    </span>
</div>


    )
}

export default Badge
