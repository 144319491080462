import React, { useState } from 'react'

function RoastingRoberta() {
    const recipes = [
        {
            "name": "Caramelized Vegetable Salmon Salad",
            "ingredients": [
                { "name": "Salmon filets", "amount": 2 },
                { "name": "Olive oil", "amount": 2, "unit": "tbsp" },
                { "name": "Butter", "amount": 1, "unit": "tbsp" },
                { "name": "Pink peppercorns", "amount": 0.5, "unit": "tsp" },
                { "name": "Salt", "amount": 1, "unit": "pinch" },
                { "name": "Arugula", "amount": 1, "unit": "cup" },
                { "name": "Fennel bulb", "amount": 0.33 },
                { "name": "Cherry tomatoes", "amount": 1, "unit": "cup" },
                { "name": "Sweet snap peas", "amount": 1, "unit": "cup" },
                { "name": "Balsamic vinegar", "amount": 1, "unit": "tbsp" },
                { "name": "Maple syrup", "amount": 1, "unit": "tbsp" },
                { "name": "Salt", "amount": 0.5, "unit": "tsp" },
                { "name": "Dried herbs", "amount": 1, "unit": "tsp" },
                { "name": "Smoked paprika", "amount": 0.5, "unit": "tsp" },
                { "name": "Chipotle powder", "amount": 1, "unit": "pinch" }
            ]
        }
    ]

    const [searchTerm, setSearchTerm] = useState('')
    const [mealPlan, setMealPlan] = useState(null)

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const filterRecipes = recipes.filter((recipe) =>
        recipe.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const generateMealPlan = () => {
        const days = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ]
        const plan = []
        const shoppingList = {}

        for (let i = 0; i < 7; i++) {
            const breakfast =
                recipes[Math.floor(Math.random() * recipes.length)]
            const dinner = recipes[Math.floor(Math.random() * recipes.length)]

            plan.push({ day: days[i], meals: [breakfast, dinner] })
            ;[breakfast, dinner].forEach((meal) => {
                meal.ingredients.forEach((ingredient) => {
                    if (shoppingList[ingredient.name]) {
                        shoppingList[ingredient.name] += ingredient.amount / 14
                    } else {
                        shoppingList[ingredient.name] = ingredient.amount / 14
                    }
                })
            })
        }

        setMealPlan({ plan, shoppingList })
    }

    return (
        <div className="p-8">
            <h1 className="mb-4 text-3xl font-bold">Recipes</h1>
            <input
                type="text"
                placeholder="Search recipes..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-full p-2 mb-4 border rounded"
            />
            <ul className="mb-4">
                {filterRecipes.map((recipe, index) => (
                    <li key={index} className="p-2 border-b">
                        {recipe.name}
                    </li>
                ))}
            </ul>
            <button
                onClick={generateMealPlan}
                className="p-2 text-white bg-blue-500 rounded"
            >
                Get Cooking Plan for the Week
            </button>
            {mealPlan && (
                <div className="mt-8">
                    <h2 className="mb-4 text-2xl font-bold">
                        Weekly Meal Plan
                    </h2>
                    {mealPlan.plan.map((dayPlan, index) => (
                        <div key={index} className="mb-4">
                            <h3 className="text-xl font-semibold">
                                {dayPlan.day}
                            </h3>
                            <ul className="list-disc list-inside">
                                {dayPlan.meals.map((meal, mealIndex) => (
                                    <li key={mealIndex}>{meal.name}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    <h2 className="mt-8 text-2xl font-bold">Shopping List</h2>
                    <ul className="list-disc list-inside">
                        {Object.entries(mealPlan.shoppingList).map(
                            ([ingredient, amount], index) => (
                                <li
                                    key={index}
                                >{`${ingredient}: ${amount}`}</li>
                            )
                        )}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default RoastingRoberta
