import React from "react"

function Askedby({ askedBy }) {
    return (
        <div className="author-container lg:w-[70%] sm:mx-10">
            <span
                id="dateBadge"
                className="bg-[#f6f4f3] text-[#AD9B9A] text-sm sm:text-base font-medium rounded dark:bg-[#f6f4f3] dark:text-[#AD9B9A]"
            >
                {!askedBy ? 'Loading...' : `asked by ${askedBy}`}
            </span>
        </div>
    )
}

export default Askedby