import React, { useState, useEffect } from 'react'
import axios from 'axios'

function AnswerForm() {
    const [answer, setAnswer] = useState('')
    const [existingAnswers, setExistingAnswers] = useState([])
    const [showExistingAnswers, setShowExistingAnswers] = useState(false)

    // Check if the user has visited the page today and already submitted an answer
    useEffect(() => {
        const userVisitedToday = localStorage.getItem('visitedToday')
        const answerSubmitted = localStorage.getItem('answerSubmitted')

        if (userVisitedToday && answerSubmitted) {
            // Fetch existing answers if the user has visited today and already submitted an answer
            fetchExistingAnswers()
        } else {
            // If the user has not visited today or has not submitted an answer, set visitedToday flag
            localStorage.setItem('visitedToday', true)
        }
    }, [])

    // Function to fetch existing answers
    const fetchExistingAnswers = async () => {
        try {
            const response = await axios.get('/getAnswers')
            let answers = response.data.answers

            // Sort answers based on createdAt date in descending order (latest first)
            answers = answers.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            )
            answers.reverse()

            // Set existing answers in state and display them
            setExistingAnswers(answers)
            setShowExistingAnswers(true)
        } catch (error) {
            console.error('Error fetching existing answers:', error)
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        // Make a POST request to submit the user's answer
        await fetch('/postendpoint', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                answer: answer,
            }),
        })

        // After submitting the answer, fetch existing answers
        fetchExistingAnswers()

        // Set answerSubmitted flag in localStorage
        localStorage.setItem('answerSubmitted', true)
    }

    return (
        <form>
            <div id="userAnswerContainer" style={{ position: 'relative' }} className='mb-2'>
                <textarea
                    id="userAnswer"
                    rows="6"
                    className="p-2.5 text-sm w-full bg-white rounded-lg border border-[#AD9B9A] focus:ring-[#AD9B9A] focus:border-[#AD9B9A] dark:border-[#AD9B9A] dark:placeholder-gray-400 dark:focus:ring-[#AD9B9A] dark:focus:border-[#AD9B9A]"
                    placeholder="Share your thoughts"
                    style={{ color: '#AD9B9A' }}
                    onChange={(c) => setAnswer(c.target.value)}
                    value={answer}
                />
                <div
                    id="buttonContainer"
                    style={{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                    }}
                >
                    <button
                        id="submitAnswerButton"
                        className="text-white bg-[#AD9B9A] hover:bg-[#22031F] focus:outline-none focus:ring-4 focus:ring-[#AD9B9A] font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-[#AD9B9A] dark:hover:[#22031F] dark:focus:ring-[#22031F] text-right"
                        onClick={submitHandler}
                    >
                        Share
                    </button>
                </div>
            </div>

            {showExistingAnswers && (
                <div className="block text-sm md:w-[60%] w-full rounded-lg focus:ring-[#AD9B9A] focus:border-[#AD9B9A] dark:border-[#AD9B9A] dark:placeholder-gray-400 dark:focus:ring-[#AD9B9A] dark:focus:border-[#AD9B9A] md:mb-2">
                    <ul className="answer-list mt-2">
                        {existingAnswers.map(
                            ({ answer, formattedDate }, index) => (
                                <li
                                    key={index}
                                    className="text-start block mb-2 p-2.5 text-sm md:w-[80%] sm:w-full bg-white rounded-lg border border-[#AD9B9A] border-1 focus:ring-[#AD9B9A] focus:border-[#AD9B9A] dark:border-[#AD9B9A] dark:placeholder-gray-400 dark:focus:ring-[#AD9B9A] dark:focus:border-[#AD9B9A]"
                                >
                                    <p>{answer}</p>
                                    <p className="text-[#AD9B9A] text-xs mt-2 text-end">
                                        {formattedDate}
                                    </p>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            )}
        </form>
    )
}

export default AnswerForm
