import React from 'react'
//import About from 'client/src/pages/About.js'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
<footer className="bg-[#f6f4f3] rounded-lg m-4 dark:bg-gray-800">
  <div className="w-full mx-auto max-w-screen-xl p-4 flex items-center justify-center">
    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-[#AD9B9A] dark:text-[#AD9B9A]">
      <li className="mx-2"> {/* Added horizontal margin */}
      <Link to="/about" className="hover:underline me-4 md:me-6">About</Link>
      </li>
      <li className="mx-2"> {/* Added horizontal margin */}
      <Link to="/archive" className="hover:underline me-4 md:me-6">Archive</Link>
      </li>
      <li> {/* No margin added */}
        <a href="timon.html" className="hover:underline me-4 md:me-6">Login for Timon</a>
      </li>
    </ul>
  </div>
</footer>



    )
}

export default Footer
